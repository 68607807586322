import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(private readonly _translate: TranslateService) {}

  ngOnInit(): void {
    this.initializeApp();
  }

  private initializeApp() {
    this._translate.setDefaultLang('en');
    this._translate.use('en');
  }
}
