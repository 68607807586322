import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomPreloadingStrategy } from '@shared/strategies/custom.preloading.strategy';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('@mobile/public/public.module').then((m) => m.PublicModule)
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      useHash: false,
      preloadingStrategy: CustomPreloadingStrategy
    })
  ],
  exports: [RouterModule]
})
export class CoreRoutingModule {}
