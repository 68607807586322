import { NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@environment/environment';
import { AppComponent } from '@mobile/app.component';
import { CoreModule } from '@mobile/core/core.module';
import { CustomPreloadingStrategy } from '@shared/strategies/custom.preloading.strategy';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.IS_PRODUCTION,
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [LocalStorageService, SessionStorageService, CustomPreloadingStrategy],
  bootstrap: [AppComponent]
})
export class AppModule {}
