import { HttpClient } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { environment } from '@environment/environment';
import { CoreRoutingModule } from '@mobile/core/core-routing.module';
import { MobileSharedModule } from '@mobile/shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AirbrakeErrorHandler } from '@shared/handlers/airbrake-error.handler';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

export const createTranslateLoader = (http: HttpClient) =>
  new TranslateHttpLoader(http, './assets/i18n/', '.json');

@NgModule({
  imports: [
    MobileSharedModule,
    CoreRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    LoggerModule.forRoot({
      level: environment.IS_PRODUCTION
        ? NgxLoggerLevel.ERROR
        : NgxLoggerLevel.DEBUG
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: ErrorHandler,
      useClass: AirbrakeErrorHandler
    }
  ]
})
export class CoreModule {}
