import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

const components = [];

const modules = [CommonModule, RouterModule, HttpClientModule];

@NgModule({
  declarations: [...components],
  imports: [...modules, TranslateModule.forChild()],
  exports: [...modules, ...components, TranslateModule]
})
export class MobileSharedModule {}
